import styled from 'styled-components';
import { GREAT_WHITE } from 'HubStyleTokens/colors';
import { MAIN_SECTION_MAX_WIDTH } from '../../constants/layout';
const MainSectionInner = styled.div.withConfig({
  displayName: "MainSectionInner",
  componentId: "sc-1c24iw6-0"
})(["margin-left:auto;margin-right:auto;max-width:", "px;width:100%;border-top:", ";"], MAIN_SECTION_MAX_WIDTH, props => props.divider ? `1px solid ${GREAT_WHITE}` : 'none');

/**
 * @deprecated use marketplace-ui-common
 */
export default MainSectionInner;