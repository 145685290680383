'use es6';

import PropTypes from 'prop-types';
import styled from 'styled-components';
const GUTTER_SIZES = {
  default: 32,
  xs: 8,
  sm: 16,
  xl: 96
};
const ResultsSection = styled.div.withConfig({
  displayName: "ResultsSection",
  componentId: "nxlr2v-0"
})(["margin-bottom:", "px;"], props => GUTTER_SIZES[props.gutterSize]);
ResultsSection.propTypes = {
  gutterSize: PropTypes.oneOf(['default', 'xs', 'sm', 'xl'])
};
ResultsSection.defaultProps = {
  gutterSize: 'default'
};
export default ResultsSection;