import PortalIdParser from 'PortalIdParser';
import { getFullUrl } from 'hubspot-url-utils';
export function getFullPublicUrl(overrideConfig = {}) {
  return getFullUrl('ecosystem', Object.assign({}, overrideConfig, {
    hubletOverride: 'na1'
  }));
}
export function getPublicLocalizedPathname(lang) {
  switch (lang) {
    case 'de':
    case 'es':
    case 'fr':
    case 'it':
    case 'ja':
    case 'nl':
    case 'pt':
    case 'zh':
    case 'fi':
    case 'da':
    case 'no':
    case 'pl':
    case 'sv':
      return `/${lang}`;
    default:
      return '';
  }
}

// TODO add nl and it once supported
export const getLocalizedHubSpotUrl = (lang, subdomain = 'www') => {
  switch (lang) {
    case 'de':
    case 'es':
    case 'fr':
      return `https://${subdomain}.hubspot.${lang}`;
    case 'ja':
      return `https://${subdomain}.hubspot.jp`;
    case 'pt':
      return 'https://br.hubspot.com';
    default:
      return `https://${subdomain}.hubspot.com`;
  }
};
const tierGroupPricingPath = {
  CRM: '/crm',
  MARKETING: '/marketing',
  SALES: '/sales',
  SERVICE: '/service',
  CMS: '/cms',
  CONTENT: '/content',
  OPERATIONS: '/operations',
  OPS: '/operations' // Remove once backend changes plans to use "OPERATIONS_" prefix
};
export const getTierGroupPricingPath = tierGroup => {
  return tierGroupPricingPath[tierGroup] || '';
};
export const getPricingUrl = (isPublic, hub, lang = 'en') => {
  const path = getTierGroupPricingPath(hub);
  if (isPublic) {
    return `${getLocalizedHubSpotUrl(lang)}/pricing${path}`;
  }
  return `${getFullUrl('app')}/pricing/${PortalIdParser.get()}${path}`;
};
const getFullInAppMarketplaceUrl = pathname => {
  return `${getFullUrl('app')}/marketplace/${pathname}`;
};
export const getTemplateMarketplaceUrl = () => getFullInAppMarketplaceUrl('templates');
export const getAppMarketplaceUrl = () => getFullInAppMarketplaceUrl('apps');
export const getSolutionsDirectoryUrl = () => getFullInAppMarketplaceUrl('solutions');